import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { LegalArticle } from '@truphone/legal-components'
import { formatDate } from '../../utils'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import { readingTime } from 'reading-time-estimator'

function LegalArticlePage({
  data: { datoCmsSite, datoCmsLegalArticle, datoCmsLegalIndex },
  pageContext: { menu, footer, cookiePrompt, locale }
}) {
  const [pageLocale, setPageLocale] = useState(locale)
  useEffect(() => {
    if (locale === 'en') {
      setPageLocale(window.navigator.language)
    }
  }, [])
  const readingTimeMinutes = readingTime(datoCmsLegalArticle.content).minutes
  return (
    <>
      <HelmetDatoCms
        seo={datoCmsLegalArticle.seoMetaTags || datoCmsSite.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <TailwindLayout
        menu={menu}
        footer={footer}
        cookiePrompt={{ ...cookiePrompt, hide: datoCmsLegalArticle.suppressCookiePrompt }}
      >
        <LegalArticle
          title={datoCmsLegalArticle.title}
          subtitle={datoCmsLegalArticle.subtitle}
          content={datoCmsLegalArticle.content}
          publishedDate={formatDate(datoCmsLegalArticle.meta.firstPublishedAt, pageLocale)}
          readingTime={
            readingTimeMinutes === 0
              ? datoCmsLegalIndex.readingTimeLessThanAMinute
              : datoCmsLegalIndex.readingTime?.replace('{minutes}', readingTimeMinutes)
          }
        />
      </TailwindLayout>
    </>
  )
}

export default LegalArticlePage

export const Query = graphql`
  query($locale: String!, $id: String!, $indexId: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsLegalIndex(originalId: { eq: $indexId }, locale: { eq: $locale }) {
      readingTime
      readingTimeLessThanAMinute
    }
    datoCmsLegalArticle(originalId: { eq: $id }, locale: { eq: $locale }) {
      seoMetaTags {
        tags
      }
      title
      subtitle
      suppressCookiePrompt
      content
      meta {
        firstPublishedAt
      }
    }
  }
`
